import addToMailchimp from "gatsby-plugin-mailchimp"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { Typography } from "@material-ui/core"
import React from "react"

export default class MailChimpForm extends React.Component {
  constructor() {
    super()
    this.state = { email: "", result: null }
  }
  _handleSubmit = async e => {
    e.preventDefault()
    const result = await addToMailchimp(this.state.email)
    if (result.result === 'error') {
      alert(`Whoops, the address ${this.state.email} is already subscribed!`)
    } else {
      alert(`Thank you for subscribing ${this.state.email}!`)
    }
    this.setState({result: result})
  }
handleChange = event => {
    this.setState({ email: event.target.value })
  }
render() {
    return (
      <form onSubmit={this._handleSubmit}>
        <TextField
          id="outlined-email-input"
          placeholder="Your email address"
          type="email"
          name="email"
          autoComplete="email"
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          variant="filled"
          onChange={this.handleChange}
        />
        <br />
        <Button
          variant="contained"
          color="primary"
          label="Submit"
          type="submit"
        >
          <Typography variant="button">Get updates</Typography>
        </Button>
      </form>
    )
  }
}