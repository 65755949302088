import React from 'react'
import Helmet from 'react-helmet'
import '../../assets/scss/init.scss'
import CookieConsent from 'react-cookie-consent'

class Layout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <div className="layout">
        <Helmet defaultTitle="Blog by Gaspare Vitta" />
        {children}
        <CookieConsent
              location="bottom"
              acceptOnScroll={true}
              acceptOnScrollPercentage={3}
              buttonText="Accept"
              cookieName="ga-cookie"
              style={{ background: "rgba(0, 0, 0, 0.6)", textAlign: "center"}}
              buttonStyle={{ display : "yes" }}
              buttonClasses='cookie-button'
            >
                This website uses <a href="https://www.iubenda.com/privacy-policy/27440418">cookies</a> to improve the user experience.
            </CookieConsent>
      </div>
    )
  }
}

export default Layout
